























































































import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api/index";
import {
  AttachmentHostType,
  SelectListItem,
  StudentDto,
  StudentDtoPagedResultDto,
  StudentFeedbackRecordCreateOrUpdateInput
} from "@/api/appService";
import Ueditor from "@/components/Ueditor/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

interface StudentFeedbackRecord extends StudentFeedbackRecordCreateOrUpdateInput {
  studentName?: string,
}

@Component({
  name: "StudentFeedbackRecordAdd",
  components: {Ueditor, MultipleUploadFile, AttachmentsView}
})
export default class StudentFeedbackRecordAdd extends Vue {
  isLoading = false;
  isEdit = false;
  labelPosition = "top";
  formData: StudentFeedbackRecord = {
    title: "",
    year: undefined,
    savedAttachments: [],
    content: "",
    studentId: undefined,
    studentName: "",
    term: undefined,
    grade: undefined
  };
  hostId = 0;
  rules = {
    title: [{required: true, message: "请输入反馈标题", trigger: "blur"}],
    studentId: [{required: true, message: "请选择学生", trigger: "change"}],
    grade: [{required: true, message: "请选择年级", trigger: "change"}],
    year: [{required: true, message: "请选择学年", trigger: "change"}],
    content: [{required: true, message: "请输入反馈详情", trigger: "blur"}],
    term: [{required: true, message: "请选择学期", trigger: "change"}]
  };
  poorStudentListBySchoolId: StudentDto[] = [];
  gradeList: SelectListItem[] = [];
  yearList: { label: string, value: number }[] = [];
  termList: { label: string, value: 1 | 2 }[] = [
    {label: "第一学期", value: 1},
    {label: "第二学期", value: 2}
  ];

  temSaveAttachmentsList: number[] = [];

  async created() {
    if (this.$route.params.studentId) {
      console.log("studentId=" + this.$route.params.studentId);
      api.student.get({id: Number(this.$route.params.studentId)}).then((res) => {
        this.formData.studentId = res.id;
        this.formData.studentName = res.name;
        this.formData.grade = res.grade;
      })
    }

    if (this.$route.params.id) {
      this.hostId = Number(this.$route.params.id);
      this.isEdit = true;
      this.$set(this.formData, "id", Number(this.$route.params.id));
      this.formData = await this.getFeedbackInfo();
    }

    await this.getSelectorValues();
  }

  @Watch("formData.savedAttachments", {deep: true})
  updateSavedAttachments(newVal: number[]) {
    if (newVal && newVal.length > 0) {
      this.temSaveAttachmentsList = newVal;
    }
  }

  async getSelectorValues() {
    //根据当前联系人所在的学校id获取该学校下的贫困生
    const res = await this.getPoorStudentListBySchoolId();
    this.poorStudentListBySchoolId = res.items!;
    this.gradeList = await this.getGradeList();
    this.createYearList();
  }

  getFeedbackInfo() {
    return api.studentFeedbackRecord.get({
      id: Number(this.$route.params.id)
    });
  }

  createYearList() {
    let year = new Date().getFullYear();
    for (let i = 0; i < 21; i++) {
      this.yearList.push({
        label: (year - i).toString(),
        value: year - i
      });
    }
  }


  get hostTypeFile() {
    return AttachmentHostType.StudentFeedback;
  }

  getGradeList() {
    return api.enumService.getValues({typeName: "StudentGrade"});
  }

  getPoorStudentListBySchoolId() {
    return api.student.getAll({
      schoolId: Number(this.$route.params.schoolId)
    });
  }

  onSubmit(formName: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$refs[formName]!.validate((valid: unknown) => {
      if (valid) {
        this.isLoading = true;
        if (!this.isEdit) {
          api.studentFeedbackRecord.create({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();
          }).catch(() => {
            this.isLoading = false;
          });
        } else {
          this.formData.savedAttachments = this.temSaveAttachmentsList;
          api.studentFeedbackRecord.update({body: this.formData}).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();
          }).catch(() => {
            this.isLoading = false;
          });
        }
      } else {
        console.log(this.formData);
        this.$message.error("提交失败");
        this.isLoading = false;
        return false;
      }
    });
  }

  back() {
    this.$router.go(-1);
  }
}
